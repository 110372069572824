
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string>; operateRoute: string } = {
    dateRange: [],
    operateRoute: this.controlRoute
  }

  private dateRange: {min: string | null; max: string | null } = {
    min: null,
    max: null
  }

  private pickerOptions = {
    // 选中日期后会执行的回调
    // 日期范围不超过30天 ，选中当天之前的时期
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.dateRange.max = date.maxDate
      this.dateRange.min = date.minDate
    },
    disabledDate: (time: any) => {
      if (this.dateRange.min) {
        return (time.getTime() > new Date(this.dateRange.min).getTime() + 60 * 60 * 24 * 30 * 1000) ||
        (time.getTime() >= Date.now()) ||
        (time.getTime() < new Date(this.dateRange.min).getTime() - 60 * 60 * 24 * 30 * 1000)
      } else {
        return time.getTime() >= Date.now()
      }
    }
  }

  private tableData = {
    loading: false,
    data: []
  }

  page = 1
  size = 10
  total = 0

  get deviceId () {
    return this.$route.params.deviceId as string || ''
  }

  get controlRoute () {
    return this.$route.query.controlRoute as string || ''
  }

  get controlDeviceNumber () {
    return this.$route.query.controlDeviceNumber as string || ''
  }

  created () {
    this.initTime()
    this.getData()
  }

  initTime () {
    this.searchInfo.dateRange = [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.tableData.loading = true
      this.$axios.get(this.$apis.devices.getHistoryByTypeId('1025'), {
        deviceId: this.deviceId,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        operateRoute: this.searchInfo.operateRoute || this.controlRoute,
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.total || 0
        this.tableData.data = res.list || []
      }).finally(() => {
        this.tableData.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
